<template>
  <table-view
    ref="tableView"
    url="/user/train/listForPage"
    :filterable="false"
    :filter-form="dataForm"
    :downloadable="isAuthed('sys_train_download')"
    download-url="/user/train/download/template"
    download-file-name="用户培训信息模板"
    :uploadable="isAuthed('sys_train_import')"
    upload-title="批量上传用户培训信息"
    upload-url="/user/train/batch"
    :upload-columns="uploadColumns"
    :batch-removable="isAuthed('sys_train_delete')"
    delete-url="/user/train/deleteById"
    :exportable="isAuthed('sys_train_export')"
    export-url="/user/train/download"
    export-file-name="用户培训信息表"
    :addable="isAuthed('sys_train_add')"
    :editable="isAuthed('sys_train_update')"
    :headers="headers"
    show-index
    selectable
    single
  >
    <el-form slot="search" @keyup.enter.native="query">
      <el-input v-model="dataForm.userName" placeholder="用户名/真实姓名"></el-input>
      <el-select v-model="dataForm.companyId" clearable filterable placeholder="所属公司">
        <el-option
          v-for="item in companyList"
          :key="item.id"
          :label="item.companyName"
          :value="item.id"
        ></el-option>
      </el-select>
      <el-button @click="query" type="primary">查询</el-button>
    </el-form>
    <el-tooltip v-if="isAuthed('sys_traincontent_add')" slot="appendButtons" content="添加培训内容" placement="top">
      <el-button icon="el-icon-document-add" circle type="infor" @click="addTrain"></el-button>
    </el-tooltip>
  </table-view>
</template>

<script>
import TableView from '@/components/templates/table-view'

export default {
  name: 'person-train',

  components: { TableView },

  data () {
    return {
      headers: [
        { id: 1, prop: 'nickName', label: '真实姓名', width: 120 },
        { id: 2, prop: 'companyName', label: '所属公司', width: 120 },
        { id: 3, prop: 'attrName', label: '培训名称', width: 180 },
        { id: 4, prop: 'attrBeginDate', label: '培训开始日期', width: 120 },
        { id: 5, prop: 'attrEndDate', label: '培训结束日期', width: 120 },
        { id: 6, prop: 'closingDate', label: '有效期截止日期', width: 150 },
        { id: 7, prop: 'remarks', label: '备注', minWidth: 200 }
      ],
      uploadColumns: [
        { id: 1, prop: 'nickName', label: '真实姓名', width: 120 },
        { id: 2, prop: 'attrName', label: '培训名称', width: 100 },
        { id: 3, prop: 'attrBeginDate', label: '培训开始日期', width: 120 },
        { id: 4, prop: 'attrEndDate', label: '培训结束日期', width: 120 },
        { id: 5, prop: 'closingDate', label: '有效期截止日期', width: 150 },
        { id: 6, prop: 'remarks', label: '备注', minWidth: 200 }
      ],
      dataForm: {
        userName: '',
        companyId: ''
      },
      companyList: [],
      trainList: []
    }
  },

  methods: {
    query () {
      this.$refs.tableView.queryData()
    },

    addTrain () {
      const _this = this
      this.$prompt('培训内容名称', '新增', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(({ value }) => {
        if (!value || !value.trim()) return
        _this.submitTrain(value)
      }).catch(() => { })
    },

    async getTrainList () {
      const data = await this.$http({
        url: this.$http.adornUrl('/user/train/dict/list'),
        method: 'post'
      })
      this.trainList = data.datas
    },

    submitTrain (name) {
      this.$http({
        url: this.$http.adornUrl('/user/train/dict/save'),
        method: 'post',
        data: {
          attrName: name
        }
      }).then(() => {
        this.getTrainList()
        this.$opts.success()
      })
    }
  },

  async created () {
    const data = await this.$http({
      url: this.$http.adornUrl('/company/list'),
      method: 'post',
      data: {}
    })
    this.companyList = data.datas
    this.getTrainList()
  }
}
</script>
